import React, { createContext, useState } from "react";

const noop = () => {};

export const AppContext = createContext({
  secondaryHeader: [],
  subHeader: [],
  breadcrumbs:null,
  loading: false,
  setSecondaryHeader: noop,
});

export const AppContextProvider = ({ children }) => {

  const setSecondaryHeader = (secondaryHeader = []) => {
    setState(prevState => ({
      ...prevState,
      secondaryHeader,
    }));
  };

  const setLoader = (loading = false) => {
    setState(prevState => ({
      ...prevState,
      loading,
    }));
  };

  const setSubHeader = (subHeader = []) => {
    setState(prevState => ({
      ...prevState,
      subHeader,
    }));
  };

  const setBreadcrumbs = (breadcrumbs = null) => {
    setState(prevState => ({
      ...prevState,
      breadcrumbs,
    }));
  };

  const initiaState = {
    secondaryHeader: [],
    subHeader: [],
    breadcrumbs:null,
    loading: false,
    setSecondaryHeader,
    setLoader,
    setSubHeader,
    setBreadcrumbs,
  };

  const [state, setState] = useState(initiaState);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>
};

export const AppContextConsumer = AppContext.Consumer;
